import Vue from 'vue'
import App from './App.vue'
import 'u-reset.css'

import router from './router'
import store from './store'
import {money} from './utils/valida.js'
import utils from '@/utils'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import '@/assets/css/common.less';
import '@/assets/css/com.css';
import '@/assets/css/iconfont.css';
import Vcharts from 'v-charts';
Vue.prototype.$rules = {money}
import api from "@/api";
import 'es6-promise/auto';
import filters from "./plugins/filters";

import md5 from 'js-md5'

Vue.use(ElementUI);
Vue.use(filters);
Vue.use(Vcharts);

Vue.config.productionTip = false
Vue.prototype.api = api;
Vue.prototype.hasPermission = utils.hasPermission
Vue.prototype.download = utils.download
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
